import React, { useState } from "react";
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default function SetDefaultEmailFrequency({ token, motor }) {
  const [defaultEmailFrequency, setDefaultEmailFrequency] = useState(
    motor && motor.defaultEmailFrequency !== undefined
      ? motor.defaultEmailFrequency
      : "daily"
  );
  const navigate = useHistory();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleFrequencyChange = (e) => {
    setDefaultEmailFrequency(e.target.value);
  };

  const handleSetFrequency = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/moteur/setDefaultEmailFrequency`,
        {
          defaultEmailFrequency: defaultEmailFrequency,
        },
        {
          headers: {
            mode: "cors",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 401) {
        showMessagePopup(
          "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
        );
        localStorage.clear();
        navigate.push("/login");
        return;
      }

      if (response.status === 200) {
        setShowSuccessMessage(true);

        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      } else {
        console.error(
          "Erreur lors de la mise à jour de la fréquence d'envoi d'e-mails"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 mt-4">
      <h3 className="text-lg md:text-xl font-semibold mb-4 text-center">
        Configurer la fréquence des mails par défaut :
      </h3>

      {/* Sélecteur de fréquence */}
      <select
        className="w-full border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 
                   text-sm md:text-base rounded-md p-2 mb-4 focus:ring-bleuSTB focus:border-bleuSTB"
        onChange={handleFrequencyChange}
        value={defaultEmailFrequency}
      >
        <option value="daily">Tous les jours</option>
        <option value="weekly">Toutes les semaines</option>
        <option value="biweekly">Toutes les deux semaines</option>
        <option value="monthly">Tous les mois</option>
      </select>

      {/* Bouton de validation */}
      <button
        className="w-full bg-bleuSTB hover:bg-marronSTB text-white text-sm md:text-base font-bold py-2 px-4 rounded 
                   transition-all duration-200"
        onClick={handleSetFrequency}
      >
        Définir Fréquence par Défaut
      </button>

      {/* Message de succès */}
      {showSuccessMessage && (
        <div className="text-green-600 dark:text-green-400 mt-4 text-center text-sm md:text-base">
          Fréquence mise à jour avec succès !
        </div>
      )}
    </div>
  );
}
