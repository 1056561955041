import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-bleuSTB text-white p-4">
      <div className="flex flex-col md:flex-row items-center justify-center">
        <div className="flex flex-col md:flex-row items-center">
          {/* Mentions légales */}
          <Link to="/mentions-legales" className="text-xl mr-4 hover:text-marronSTB mb-2 md:mb-0">
            Mentions légales
          </Link>
          {/* Séparateur visible uniquement sur les écrans larges */}
          <span className="text-xl mx-4 hidden md:block">|</span>
          {/* Séparateur visible uniquement sur les petits écrans */}
          <span className="text-xl mx-4 md:hidden block">---</span>
          {/* Développeur ALFenvironnement */}
          <span className="text-xl mb-2 md:mb-0">
            Développé par{" "}
            <a
              href="https://alf-environnement.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-orangeALF hover:text-marronSTB"
            >
              ALFenvironnement
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
