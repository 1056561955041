import React, { useState, useEffect } from "react";
import axios from "axios"; 
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";

export default function EditUserForm({ id, rafraichir, token, admin }) {
  const [newUser, setNewUser] = useState({ mail: "" });
  const [messageMailInvalide, setMessageMailInvalide] = useState("");
  const [messageUpdateMail, setMessageUpdateMail] = useState("");
  const navigate = useHistory();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessageMailInvalide("");
      setMessageUpdateMail("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messageMailInvalide, messageUpdateMail]);

  const handleChange = (event) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(newUser.mail)) {
      setMessageMailInvalide("Adresse e-mail invalide");
      return;
    }

    const userId = id;
    const userEmail = newUser.mail;

    axios.patch(
      `${API_URL}/user/update`,
      {
        userId: userId,
        email: userEmail,
        admin: admin,
      },
      {
        headers: {
          accept: "*/*",
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
          localStorage.clear();
          navigate.push("/login");
        } else {
          return res.data;
        }
      })
      .then(
        (result) => {
          setMessageUpdateMail(result.message);
          rafraichir();
        },
        (error) => {
          console.log(error.message);
        }
      );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center w-full max-w-lg mx-auto px-4 sm:px-6 lg:px-8"
    >
      <label className="w-full">
        <input
          className="w-full text-sm md:text-base lg:text-xl border-2 border-bleuCielSTB focus:border-bleuSTB rounded-3xl p-3 text-center mt-4 mb-4"
          onChange={handleChange}
          value={newUser.mail}
          placeholder="Nouvelle adresse mail"
          name="mail"
          type="email"
        />
      </label>
      <input
        type="submit"
        value="Changer l'adresse mail"
        className="bg-bleuSTB hover:bg-marronSTB text-white text-sm md:text-base lg:text-lg xl:text-xl rounded-full py-2 px-6 cursor-pointer transition-all duration-300"
      />
      {messageMailInvalide && (
        <p className="bg-red-500 text-white text-sm md:text-base lg:text-lg xl:text-xl rounded-lg px-4 py-2 mt-4">
          {messageMailInvalide}
        </p>
      )}
      {messageUpdateMail && (
        <p className="bg-green-500 text-white text-sm md:text-base lg:text-lg xl:text-xl rounded-lg px-4 py-2 mt-4">
          {messageUpdateMail}
        </p>
      )}
    </form>
  );
}
