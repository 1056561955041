import "./admin.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { API_URL } from "../constants";
import UserTable from "./UserTable";
import AddUserForm from "./AddUserForm";
import EditUserForm from "./EditUserForm";
import ChangePasswordForm from "./ChangePasswordForm";
import SearchMoteur from "./SearchMoteur";
import MoteurList from "./MoteurList";
import SetDefaultEmailFrequency from "./SetDefaultEmailFrequency";

export default function Admin(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;
  const [motor] = useState(
    localStorage.getItem("motor")
      ? JSON.parse(localStorage.getItem("motor"))
      : props.selectedMotor
  );
  const token = user.token;
  const userId = user.userId;
  const navigate = useHistory();

  const [users, setUsers] = useState([{ _id: "", email: "" }]);
  const [, setNewser] = useState([{ mail: "", mdp: "" }]);
  const [id, setId] = useState();
  const [mail, setMail] = useState();
  const [admin, setAdmin] = useState();

  const [messagemail, setMessageMail] = useState();
  const [message, setMessage] = useState();
  const [messageSupp, setMessageSupp] = useState();
  const [messageMoteur, setMessageMoteur] = useState();
  const [messageMoteurErreur, setMessageMoteurErreur] = useState();
  const [moteurs, setMoteurs] = useState([]);

  const [showMoteurList, setShowMoteurList] = useState(true);

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessageMail(null);
      setMessage(null);
      setMessageSupp(null);
      setMessageMoteur(null);
      setMessageMoteurErreur(null);
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messagemail, message, messageSupp, messageMoteur, messageMoteurErreur]);

  /*
  met a jour le message a chaque fois qu'on selectionne un nouveau id
  */
  useEffect(() => {
    setMessage();
    setNewser({ [mail]: "" });
  }, [id , mail]);

  /*
  permet de recuperer la liste des utilisateurs enregistrer dans la base de données
  */
  useEffect(() => {
    axios.get(`${API_URL}/user`, {
      headers: {
        mode: "cors",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      if (res.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate.push("/login");
      } else {
        return res.data;
      }
    })
      .then(
        (result) => {
          setUsers(result);
        },
        (error) => {
          console.log(error.message);
        }
      );
  }, [navigate , token]);

  /*
  recupere l'id utilisateur selectionnée et envoie au serveur un POST afin de supprimer cette utilisateur
  */
  function suppr(id_utilisateur) {
    axios.post(`${API_URL}/user/suppr`, {
      id: id_utilisateur,
    }, {
      headers: {
        mode: "cors",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      if (res.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate.push("/login");
      } else {
        return res.data;
      }
    })
      .then(
        (result) => {
          setMessageSupp(result.message);
          rafraichir();
        },
        (error) => {
          console.log(error.message);
        }
      );
  }

  /*
  permet de réclamer la mise a jour de la liste des utilisateur
  */
  function rafraichir() {
    axios.get(`${API_URL}/user`, {
      headers: {
        mode: "cors",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      if (res.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate.push("/login");
      } else {
        return res.data;
      }
    })
      .then(
        (result) => {
          console.log(result);
          setUsers(result);
        },
        (error) => {
          console.log(error.message);
        }
      );
  }

  useEffect(() => {
    getMotorsList();
  }, [userId]);

  const setAdminRole = async (userId, isAdmin) => {
    try {
      const response = await axios.put(`${API_URL}/user/setadmin`, {
        id: userId,
        isAdmin: isAdmin,
      }, {
        headers: {
          mode: "cors",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate.push("/login");
        return;
      }

      const data = response.data;

      if (response.status === 200) {
        console.log(data.message);
        if (userId === user.userId && !isAdmin) {
          navigate.push("/motorsList");
        }
      } else {
        console.log(data.error);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const getMotorCount = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/moteur/countmoteurs?id=${userId}`,
        {
          headers: {
            mode: "cors",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear();
        navigate.push("/login");
        return;
      }
      return response.data.count;
    } catch (error) {
      console.error("Erreur lors de la récupération du nombre de moteurs:", error.message);
      return 0;
    }
  };
  
  const getMotorsList = async () => {
    if (userId !== "") {
      try {
        const totalCount = await getMotorCount(); // Récupère le nombre total de moteurs.
        const response = await axios.get(
          `${API_URL}/moteur/listmoteur?id=${userId}&limit=${totalCount}`,
          {
            headers: {
              mode: "cors",
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
          localStorage.clear();
          navigate.push("/login");
          return;
        }
        const json = response.data;
        setMoteurs(json.data);
        console.log(json.data);
      } catch (error) {
        console.error("Erreur lors de la récupération de la liste des moteurs:", error.message);
      }
    }
  };
  

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMoteur, setSelectedMoteur] = useState(null);
  const [selectedMoteurId, setSelectedMoteurId] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSelectedMoteur(null);
    setShowMoteurList(true);
  };

  const filteredMoteurs = moteurs.filter((moteur) => {
    return (
      moteur.nom_moteur.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !moteur.id_user.some((userId) => userId === id)
    );
  });

  const handleMoteurClick = (moteur) => {
    setSelectedMoteur(moteur.nom_moteur);
    setSearchTerm(moteur.nom_moteur);
    setSelectedMoteurId(moteur._id);
    setShowMoteurList(false);
  };

  const ajouterMoteurUtilisateur = async () => {
    try {
      const response = await axios.post(`${API_URL}/moteur/ajoutmoteur`, {
        id_user: id,
        id_moteur: selectedMoteurId,
      }, {
        headers: {
          mode: "cors",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate.push("/login");
        return;
      }
      const json = response.data;
      console.log(json.data);
      if (response.status === 200) {
        setMessageMoteur("Le moteur a été ajouté avec succès");
      } else {
        setMessageMoteurErreur("Erreur : le moteur n'a pas été ajouté");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleAjouterMoteur = () => {
    ajouterMoteurUtilisateur();
    window.location.reload();
  };

  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
    <Header user={user} />
    <div className="flex flex-auto h-full w-full items-center justify-center sm:p-4">
      <div className="border-4 border-bleuSTB rounded-2xl w-full text-xs sm:text-base sm:w-auto flex flex-col-reverse  lg:flex-row p-6">
        <div id="flex flex-col items-center">
          <h2 className="text-center text-bleuSTB mb-6 text-lg sm:text-xl xl:text-5xl font-bold">Gestion Utilisateurs</h2>
          {messageSupp && (
            <span id="flex justify-center mt-4" className="bg-green-500 rounded-md text-white mx-auto py-2 px-4 text-center mt-4 mb-4 text-xs flex items-center justify-center">
              {messageSupp}
            </span>
          )}
        <div className="w-full overflow-x-auto">
          <UserTable
            users={users}
            deleteUser={suppr}
            setSelectedUserId={setId}
            setSelectedUserEmail={setMail}
            setAdminRole={setAdminRole}
            rafraichir={rafraichir}
            admin={setAdmin}
          />
        </div>
      </div>
  
      {/* Section droite : Formulaires */}
      <div className="lg:w-1/2 w-full flex flex-col p-8">
        {/* Ajouter un utilisateur */}
        <div className="sm:flex sm:flex-col border-4 border-bleuSTB rounded-2xl p-6 mb-4">
          <AddUserForm rafraichir={rafraichir} token={token} />
        </div>
            {id ? (
                    <div className="flex flex-col border-4 border-bleuSTB rounded-2xl p-4 text-base lg:text-2xl">
                      <span className="text-center text-bleuSTB 3xl:text-3xl">{mail}</span>
                      <EditUserForm id={id} rafraichir={rafraichir} token={token} admin={admin} />
                      <ChangePasswordForm id={id} token={token} />
                      <>
                        <SearchMoteur
                          searchTerm={searchTerm}
                          handleSearch={handleSearch}
                          selectedMoteur={selectedMoteur}
                          handleAjouterMoteur={handleAjouterMoteur}
                          messageMoteur={messageMoteur}
                          messageMoteurErreur={messageMoteurErreur}
                        />
                        {showMoteurList && searchTerm !== "" && filteredMoteurs.length > 0 && (
                          <MoteurList
                            filteredMoteurs={filteredMoteurs}
                            handleMoteurClick={handleMoteurClick}
                          />
                        )}
                      </>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-col border-4 border-bleuSTB rounded-2xl mt-4 p-4 text-base lg:text-2xl">
              <SetDefaultEmailFrequency token={token} motor={motor} />
                  </div>
            </div>
        </div>
        </div>
      <Footer />
  </div>
  );
}
