import React from "react";
import "./admin.css";

export default function SearchMoteur({
  searchTerm,
  handleSearch,
  selectedMoteur,
  handleAjouterMoteur,
  messageMoteur,
  messageMoteurErreur,
}) {
  return (
    <div className="flex flex-col items-center w-full p-4">
      {/* Champ de recherche */}
      <label className="w-full">
        <input
          className="w-full text-sm md:text-base lg:text-lg border-2 border-bleuCielSTB focus:border-bleuSTB rounded-3xl p-3 text-center mt-4 mb-4"
          type="text"
          placeholder="Entrez le nom d'un moteur..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </label>

      {/* Section pour les messages ou le bouton d'ajout */}
      {selectedMoteur ? (
        <div className="text-center flex flex-col items-center w-full">
          <input
            type="submit"
            value="Ajouter le moteur"
            onClick={handleAjouterMoteur}
            className="bg-bleuSTB hover:bg-marronSTB text-white text-sm md:text-lg lg:text-xl 3xl:text-2xl 
                       border-none rounded-full p-2 w-48 sm:w-60 mt-2 cursor-pointer transition-all duration-200"
          />

          {messageMoteur && (
            <span className="bg-green-500 rounded-lg text-white mx-auto p-2 mt-4 text-center 
                             text-sm md:text-base lg:text-xl w-3/4 sm:w-1/2 flex items-center justify-center">
              {messageMoteur}
            </span>
          )}

          {messageMoteurErreur && (
            <span className="bg-error rounded-lg text-white mx-auto p-2 mt-4 text-center 
                             text-sm md:text-base lg:text-xl w-3/4 sm:w-1/2 flex items-center justify-center">
              {messageMoteurErreur}
            </span>
          )}
        </div>
      ) : searchTerm !== "" ? (
        <></>
      ) : (
        <></>
      )}
    </div>
  );
}
