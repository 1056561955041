import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Brush,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { FaEdit } from 'react-icons/fa';

function Graphique(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [graphName, setGraphName] = useState(props.name);

  const data = props.valdata;
  const type = props.type;


  const motor = localStorage.getItem('motor') ? JSON.parse(localStorage.getItem('motor')) : props.motor;

  const dataWithoutUndefined = props.valdata.map((item) => ({
    ...item,
    ordonne: item.ordonne || 0,
  }));

  useEffect(() => {
    const storedGraphName = localStorage.getItem(`graphName_${motor._id}_${props.name}`);
    console.log('Stored Graph Name:', `graphName_${motor._id}_${props.name}`); // Vérifiez ce qui est récupéré
    if (storedGraphName) {
      setGraphName(storedGraphName.trim());
    } else {
      setGraphName(props.name);
    }
  }, [motor._id, props.name]);
  
  

  const handleInputChange = (e) => {
    setGraphName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // Stocker le nom du graphique avec une clé unique
    localStorage.setItem(`graphName_${motor._id}_${props.name}`, graphName);
  };

  // Liste des graphiques éditables
  const editableGraphs = ["Vitesse RMS axe X", "Vitesse RMS axe Y", "Vitesse RMS axe Z"];

  let graph;


  const colorCurrent = (valeur) => (valeur > props.valdata[0].ordonne * 1.1 ? "red" : "#8884d8");

  const color = (valeur) => {
    switch (type) {
      case "type1":
        if (valeur < 1.4) {
          return "green";
        }
        if (valeur < 2.8) {
          return "yellow";
        }
        if (valeur < 4.5) {
          return "orange";
        }
        return "red";

      case "type2":
      case "type3":
        if (valeur < 2.3) {
          return "green";
        }
        if (valeur < 4.5) {
          return "yellow";
        }
        if (valeur < 7.1) {
          return "orange";
        }
        return "red";

      case "type4":
        if (valeur < 3.5) {
          return "green";
        }
        if (valeur < 7.1) {
          return "yellow";
        }
        if (valeur < 11) {
          return "orange";
        }
        return "red";

      default:
        return "indigo";
    }
  };

  if (props.ordonne === "temperature") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 30, bottom: 10, left: 30 }} data={props.valdata}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            name={props.abscisse}
            unit={props.uniteabscisse}
          />
          <YAxis
            type={props.typeY}
            dataKey={props.keyord}
            name={props.ordonne}
            unit={props.uniteordonne}
          />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Line
            name="donnee"
            dataKey={props.keyord}
            stroke="indigo"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else if (props.ordonne === "vitesse") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 62, bottom: 10, left: 50 }} data={props.valdata} barSize={10}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            unit={props.uniteabscisse}
            padding={{ right: 10 }}
          />
          <YAxis unit={props.uniteordonne} />
          <Tooltip />
          <Brush dataKey={props.keyabs} stroke="indigo" />
          <Line
            type="linear"
            name={props.ordonne}
            dataKey={props.keyord}
            stroke={(props.ordonne === "vitesse" && props.valdata && props.valdata[0]) ? colorCurrent(props.valdata[0].courant) : "#8884d8"}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }  else if (props.ordonne === "frequence") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={props.valdata} barSize={10}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            unit={props.uniteabscisse}
            padding={{ right: 10 }}
          />
          <YAxis unit={props.uniteordonne} />
          <Tooltip />
          <Brush dataKey={props.keyabs} stroke="indigo" />
          <Line
            type="linear"
            name={props.ordonne}
            dataKey={props.keyord}
            stroke={(props.ordonne === "fréquence" && props.valdata && props.valdata[0]) ? colorCurrent(props.valdata[0].courant) : "#8884d8"}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else if (props.ordonne === "courant") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={props.valdata} barSize={10}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            unit={props.uniteabscisse}
            padding={{ right: 10 }}
          />
          <YAxis unit={props.uniteordonne} />
          <Tooltip />
          <Brush dataKey={props.keyabs} stroke="indigo" />
          <Line
            type="linear"
            name={props.ordonne}
            dataKey={props.keyord}
            stroke={(props.ordonne === "courant" && props.valdata && props.valdata[0]) ? colorCurrent(props.valdata[0].courant) : "#8884d8"}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else if (props.ordonne === "consommation") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={dataWithoutUndefined}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            unit={props.uniteabscisse}
            padding={{ right: 10 }}
          />
          <YAxis unit={props.uniteordonne} />
          <Tooltip />
          <Brush dataKey={props.keyabs} stroke="indigo" />
          <Line
            type="linear"
            name={props.ordonne}
            dataKey={props.keyord}
            stroke={(props.ordonne === "consommation" && props.valdata && props.valdata[0]) ? colorCurrent(props.valdata[0].courant) : "#8884d8"}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else if (props.ordonne === "velocité") {
    graph = (
      <ResponsiveContainer height={250}>
        <LineChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={props.valdata} barSize={10}>
          <CartesianGrid strokeDasharray="10 1" />
          <XAxis
            type={props.typeX}
            dataKey={props.keyabs}
            unit={props.uniteabscisse}
            padding={{ right: 10 }}
          />
          <YAxis unit={props.uniteordonne} />
          <Tooltip />
          <Brush dataKey={props.keyabs} stroke="indigo" />
          <Line
            type="linear"
            name={props.ordonne}
            dataKey={props.keyord}
            stroke={(props.ordonne === "courant" && props.valdata && props.valdata[0]) ? colorCurrent(props.valdata[0].courant) : "#8884d8"}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else {
    graph = (
      <ResponsiveContainer height={250}>
  <BarChart margin={{ top: 20, right: 62, bottom: 10, left: 40 }} data={props.valdata} barSize={2}>
    <CartesianGrid strokeDasharray="10 1" />
    <XAxis
      type={props.typeX}
      dataKey={props.keyabs}
      unit={props.uniteabscisse}
      padding={{ right: 10 }}
    />
    <YAxis unit={props.uniteordonne} />

    <Tooltip
      labelFormatter={(label, payload) => {
        const timestamp = payload[0]?.payload?.timestamp || ''; // Récupération du timestamp
        return `${timestamp} | | ${label}`; // Affichage du timestamp avec la fréquence (label)
      }}
      formatter={(value, name) => [`${name}: ${value}`]} // Affiche simplement les valeurs sans ajout de fréquence ici
    />
    
    <Brush dataKey={props.keyabs} stroke="indigo" />
    <Bar name={props.ordonne} dataKey={props.keyord} fill="indigo">
      {props.valdata.map((entry, index) => (
        <Cell fill={color(entry.ampli)} key={index} />
      ))}
    </Bar>
  </BarChart>
</ResponsiveContainer>

    );
  }

  return (
    <div className="flex flex-col justify-center items-center w-full lg:w-1/2 m-1 lg:m-auto mb-10">
      <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl ml-5 font-bold text-center">
        {isEditing && graphName.startsWith("Vitesse RMS axe") ? (
          <input
            type="text"
            value={graphName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleBlur(); // Fermer l'édition si l'utilisateur appuie sur "Enter"
              }
            }}
            autoFocus
            className="border-2 border-gray-300 rounded-md p-2 focus:outline-none focus:border-blue-500"
          />
        ) : (
          <div className="flex items-center">
            <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl ml-5 font-bold cursor-pointer">
              {graphName} {/* Afficher le nom du graphique */}
            </h2>
            {editableGraphs.includes(graphName) && (
              <FaEdit
                className="ml-4 cursor-pointer hover:text-blue-600"
                onClick={() => setIsEditing(true)}
              />
            )}
          </div>
        )}
      </div>
      {graph}
    </div>
  );

}

export default Graphique;
