import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default function ChangePasswordForm({ id, token }) {
  const [newuser, setNewUser] = useState({ mail: "", mdp: "" });
  const [messagePassword, setMessagePassword] = useState("");
  const navigate = useHistory();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessagePassword("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messagePassword]);

  const handleChange2 = (event) => {
    setNewUser({
      ...newuser,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit3 = (event) => {
    event.preventDefault();

    if (newuser.mdp.length < 8) {
      setMessagePassword("ERREUR : Le mot de passe doit contenir au moins 8 caractères.");
      return;
    }

    axios.post(
      `${API_URL}/user/changemdpAdmin`,
      {
        id: id,
        password: newuser.mdp,
      },
      {
        headers: {
          mode: "cors",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          showMessagePopup(
            "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
          );
          localStorage.clear();
          navigate.push("/login");
        } else {
          return res.data;
        }
      })
      .then(
        (result) => {
          setMessagePassword(result.message);
        },
        (error) => {
          console.log(error.message);
        }
      );
  };

  return (
    <form
      onSubmit={handleSubmit3}
      className="flex flex-col items-center w-full max-w-lg mx-auto px-4 sm:px-6 lg:px-8"
    >
      <label className="w-full">
        <input
          className="w-full text-sm md:text-base lg:text-lg border-2 border-bleuCielSTB focus:border-bleuSTB rounded-3xl p-3 text-center mt-4 mb-4"
          onChange={handleChange2}
          value={newuser.mdp}
          placeholder="Nouveau mot de passe"
          name="mdp"
          type="password"
        />
      </label>
        <input
          type="submit"
          value="Changer le mot de passe"
          className="bg-bleuSTB hover:bg-marronSTB text-white text-sm md:text-base lg:text-lg xl:text-xl rounded-full py-2 px-6 cursor-pointer transition-all duration-300"
        />
      {messagePassword && (
        <div className="w-full mt-4">
          <span
            id="message"
            className="bg-green-500 rounded-lg text-white px-4 py-2 text-center text-sm md:text-base lg:text-lg"
          >
            {messagePassword}
          </span>
        </div>
      )}
    </form>
  );
}
