import React, { useState, useEffect } from 'react';
import Modal from "../Etalonnage/modal";
import useModal from "../Etalonnage/useModal";
import axios from "axios";
import { API_URL } from "../constants";

const InfosMoteur = ({ motor, token, setMotor }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.userId;

  const { isShowing, toggle } = useModal();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentCourantNominal, setCurrentCourantNominal] = useState(motor.courantNom);

  // Récupération des données du moteur à chaque changement de motor._id
  useEffect(() => {
    const fetchMotorData = async () => {
      try {
        const response = await axios.get(`${API_URL}/moteur/listmoteur?id=${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200) {
          const motorData = response.data.data.find(m => m._id === motor._id);
          if (motorData) {
            setMotor(motorData);
            setCurrentCourantNominal(motorData.courantNom);
          }
        } else {
          setErrorMessage("Erreur lors de la récupération des données du moteur.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations du moteur:", error);
        setErrorMessage("Erreur lors de la récupération des données du moteur.");
      }
    };

    fetchMotorData();
  }, [motor._id, userId, token, setMotor]);

  // Mise à jour de courantNominal lors du changement du moteur
  const handleChangeCourantNominal = (e) => {
    setCurrentCourantNominal(e.target.value);
  };

  // Envoi des données de courant nominal
  const handleSubmitCourantNominal = async (e) => {
    e.preventDefault();
    const adjustedData = { id_moteur: motor._id, courantNom: currentCourantNominal };

    try {
      const response = await axios.post(`${API_URL}/moteur/courantnominal`, adjustedData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        const updatedMotor = response.data;
        setMotor(updatedMotor);
        setSuccessMessage("Les données de courant nominal ont été envoyées avec succès.");
        setErrorMessage("");
        setTimeout(() => setSuccessMessage(""), 3000);
      } else {
        setErrorMessage("Échec de l'envoi des données de courant nominal.");
      }
    } catch (error) {
      setErrorMessage("Erreur lors de l'envoi des données de courant nominal.");
      console.error("Erreur lors de l'envoi des données:", error);
    }
  };

  return (
    <>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline">
        <span className="inline-block text-decoration-none font-light mb-4">
          {motor.tension === "mono" ? "Alimentation monophasé" : "Alimentation triphasé"}
        </span>
      </h4>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
        Fréquence d'alimentation
        <br />
        <span className="inline-block text-decoration-none font-light mb-4 mt-4">{motor.freqAlim} Hz</span>
      </h4>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
        Fréquence de rotation
        <br />
        <span className="inline-block text-decoration-none font-light mb-4 mt-4">
          {parseInt(motor.freqRot * 60)} tr/min
        </span>
      </h4>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
        <form className="space-y-4" onSubmit={handleSubmitCourantNominal}>
          <div>
            <label className="pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">Courant nominal</label>
            <br />
            <input 
              type="text" 
              name="current" 
              onChange={handleChangeCourantNominal} 
              value={currentCourantNominal}
              className="text-xs font-light mt-4 lg:text-base 3xl:text-xl text-center border-2 border-orangeButton hover:border-bleuSTB rounded-xl p-2 w-[65%] sm:w-[20%]"
              pattern="\d*\.?\d+" 
              title="Veuillez entrer un nombre valide"
            />
            <span className="inline-block text-decoration-none font-light text-base lg:text-xl ml-2 mr-2">A</span>
            <button 
              type="submit" 
              className="bg-bleuSTB hover:bg-marronSTB mt-4 sm:mt-0 text-white font-light py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline 3xl:text-xl text-base ml-5"
            >
              Valider
            </button>
          </div>
        </form>
        {successMessage && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-500 border border-green-700 text-white px-4 py-3 rounded transition-opacity duration-500 ease-in-out opacity-100">
            <strong className="font-bold">Succès!</strong>
            <span className="block sm:inline"> {successMessage}</span>
          </div>
        )}
        {errorMessage && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-500 border border-red-700 text-white px-4 py-3 rounded transition-opacity duration-500 ease-in-out opacity-100">
            <strong className="font-bold">Erreur!</strong>
            <span className="block sm:inline"> {errorMessage}</span>
          </div>
        )}
      </h4>
      <h4 className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline font-bold">
        <span className="inline-block text-decoration-none font-light mb-4 mt-4">{motor.type}</span>
      </h4>
    </>
  );
};

export default InfosMoteur;
