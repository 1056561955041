import React, { useState } from 'react';
import {FaChevronDown } from 'react-icons/fa';

export default function ErrorMessages({ motor }) {
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const toggleErrorDetails = () => {
    setShowErrorDetails(!showErrorDetails);
  };

  return (
    <div className="mt-8">
      {motor.etatVibratoire.length > 0 && (
        <div
          className={`flex items-center justify-between bg-red-500 text-white py-4 px-6 cursor-pointer rounded-2xl`}
          style={{
            borderBottomLeftRadius: showErrorDetails ? '0' : '0.4em',
            borderBottomRightRadius: showErrorDetails ? '0' : '0.4em',
          }}
          onClick={toggleErrorDetails}
        >
          <span className="mr-auto ml-auto text-xs sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-center">
            ⚠️ Nombre de messages d'erreurs : {motor.etatVibratoire.length} ⚠️
          </span>

          <span>
            <FaChevronDown
              className={`ml-8 transition-transform duration-300 transform ${
                showErrorDetails ? 'rotate-180' : ''
              }`}
            />
          </span>
        </div>
      )}
      {showErrorDetails && (
        <ul className="bg-[#ebb9b0] border-4 border-red-500 rounded-bl-lg rounded-br-lg border-t-0 text-red-700 list-none m-0 p-4">
          {motor.etatVibratoire.map((errorMessage, index) => (
            <li key={index} className='border-b-2 border-white text-base lg:text-3xl 3xl:text-4xl mb-8 mt-4'>{errorMessage}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
