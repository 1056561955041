import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";

export default function AddMaintenance({
  newMaintenanceName,
  handleInputChange,
  newFreqIntervention,
  setNewFreqIntervention,
  ajouterMaintenance,
  error,
  isSuggestionClicked,
  handleSuggestionClick,
  token
}) {

  const [showAddNewMaintenanceForm, setShowAddNewMaintenanceForm] = useState(false);
  const [maintenanceSuggestions, setMaintenanceSuggestions] = useState([]);
  const navigate = useHistory();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const ajouterNewMaintenance = async () => {
    setShowAddNewMaintenanceForm(true);

    try {
      const response = await axios.get(
        `${API_URL}/typo/maintenancelist`,
        {
          headers: {
            mode: "cors",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const json = response.data;
      if (response.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear(); 
        navigate.push("/login");
        return;
      }
      if (response.status === 200) {
        const suggestions = json.data;
        setMaintenanceSuggestions(suggestions);
      } else {
        console.error(
          "Erreur lors de la récupération des suggestions de maintenance"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fonction pour annuler et réinitialiser le formulaire
  const annulerFormulaire = () => {
    setShowAddNewMaintenanceForm(false);
    // Réinitialiser les valeurs des champs
    handleInputChange({ target: { name: "newMaintenanceName", value: "" } });
    setNewFreqIntervention("");
  };

  return (
    <div>
      {!showAddNewMaintenanceForm ? (
        <h4 className="border-solid text-center lg:text-left border-4 border-b-0 border-t-0 border-r-0 border-l-0 border-orangeButton pt-4 pb-4 text-xl">
          <button
            className="bg-bleuSTB hover:bg-orange-500 text-white text-base border-none rounded-xl p-2 w-40 sm:w-60 cursor-pointer 3xl:text-2xl"
            onClick={ajouterNewMaintenance}
          >
            Ajouter votre maintenance
          </button>
        </h4>
      ) : (
        <div>
          <h4 className="border-solid  text-center lg:text-left border-4 border-b-0 border-t-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl">
            <input
              type="text"
              value={newMaintenanceName}
              onChange={handleInputChange}
              placeholder="Nom de la maintenance"
              className="lg:text-base text-xs text-center 3xl:text-2xl 3xl:w-[35%] border-2 border-orangeButton hover:border-bleuSTB rounded-xl p-2 w-[70%] mt-5 lg:mt-0 lg:w-[30%] mr-4"
            />
            <input
              type="number"
              value={newFreqIntervention}
              onChange={(e) => {
                const newValue = e.target.value;
                setNewFreqIntervention(newValue);
              }}
              placeholder="Fréquence d'intervention"
              className="lg:text-base text-xs text-center 3xl:text-2xl  3xl:w-[35%]  border-2 border-orangeButton hover:border-bleuSTB rounded-xl p-2 w-[70%] mt-5 lg:mt-0 lg:w-[30%] mr-4"
              min="0"
            />
            <div className="flex justify-center lg:justify-normal gap-4 mt-5">
              <button
                className="bg-bleuSTB hover:bg-marronSTB text-white text-base 3xl:text-2xl border-none rounded-xl p-2 w-[40%] cursor-pointer"
                onClick={ajouterMaintenance}
              >
                Valider
              </button>
              {/* Bouton Annuler */}
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white text-base 3xl:text-2xl border-none rounded-xl p-2 w-[40%] cursor-pointer"
                onClick={annulerFormulaire}
              >
                Annuler
              </button>
            </div>
          </h4>
          {error && <p className="bg-red-500 rounded-md text-white text-base m-0 p-2 text-center">{error}</p>}
          {newMaintenanceName &&
            !isSuggestionClicked &&
            maintenanceSuggestions.length > 0 && (
              <ul className="list-none p-0 m-0 mt-4 text-xl 3xl:text-3xl font-semibold">
                {maintenanceSuggestions
                  .filter((suggestion) =>
                    suggestion.nomMaintenance
                      .toLowerCase()
                      .startsWith(newMaintenanceName.toLowerCase())
                  )
                  .map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="p-2 cursor-pointer bg-gray-200 rounded-md mb-4 text-gray-700 transition-colors duration-300 hover:bg-gray-300"
                    >
                      <span className="mr-5 text-2xl 3xl:text-3xl">{'→'}</span>{suggestion.nomMaintenance}
                    </li>
                  ))}
              </ul>
            )}
        </div>
      )}
    </div>
  );
}
