import React from "react";
import "./admin.css";

export default function MoteurList({ filteredMoteurs, handleMoteurClick }) {
  return (
    <div className="list-none m-0 p-0 text-lg sm:text-xl 3xl:text-2xl">
      {filteredMoteurs.map((moteur) => (
        <div key={moteur._id}>
          <li
            className="p-4 mb-2 bg-gray-100 rounded-md border border-gray-300 
                       transition-all duration-200 ease-in-out 
                       hover:bg-gray-200 hover:shadow-md hover:-translate-y-1 
                       cursor-pointer text-center sm:text-left sm:flex sm:items-center 
                       sm:justify-between"
            onClick={() => handleMoteurClick(moteur)}
          >
            <span className="font-semibold">{moteur.nom_moteur}</span>
          </li>
        </div>
      ))}
      {filteredMoteurs.length === 0 && (
        <div className="bg-red-500 rounded-lg text-white text-lg sm:text-xl mx-auto 
                        p-4 text-center mt-8 flex items-center justify-center 
                        w-4/5 sm:w-2/3 lg:w-1/2">
          Le moteur recherché n'existe pas.
        </div>
      )}
    </div>
  );
}
